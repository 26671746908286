<template>
  <div class="page">
    <div class="bg">
      <img src="./img/login-bg.png" alt="" />
    </div>
    <div class="detail">
      <div class="title">用户登录</div>
      <div class="input-box">
        <div class="left">
          <img class="img1" src="./img/login-icon1.png" alt="" />
        </div>
        <div class="right">
          <input type="text" placeholder="请输入账号" v-model="account" />
        </div>
      </div>
      <div class="input-box">
        <div class="left">
          <img class="img2" src="./img/login-icon2.png" alt="" />
        </div>
        <div class="right">
          <input type="passWord" placeholder="请输入密码" v-model="passWord" />
        </div>
      </div>
      <div class="btn" @click="login">登录</div>
    </div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
export default {
  name: "login",
  data() {
    return {
      account: "",
      passWord: "",
    };
  },
  methods: {
    setAESCbcEncrypt(text) {
      const cbcPkcs7Key = "H@M7e4o%dUb@JTwx";
      return CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(cbcPkcs7Key), {
        iv: CryptoJS.enc.Utf8.parse(cbcPkcs7Key),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
    },
    async login() {
      if (this.account == "") {
        this.$toast("请输入账号！");
        return;
      }
      if (this.passWord == "") {
        this.$toast("请输入密码！");
        return;
      }
      let url = `/gateway/hc-auth/token`;
      const formData = new FormData();
      formData.append("account", this.setAESCbcEncrypt(this.account));
      formData.append("password", this.setAESCbcEncrypt(this.passWord));
      formData.append("grantType", "password");
      let isQinxue = true;
      let res = await this.$axios.post(`${url}`, formData, {
        isQinxue: true,
        headers: {
          "User-Type": "web",
        },
      });
      if (res.code == 200) {
        sessionStorage.setItem("userInfo", JSON.stringify(res.data));
        this.$router.push({
          name: "studySignList",
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  .detail {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 50px;
    box-sizing: border-box;
    .btn {
      margin-top: 116px;
      width: 652px;
      height: 88px;
      background: linear-gradient(73deg, #73d9ff 0%, #3c9cff 100%);
      box-shadow: 0px 4px 22px 0px rgba(197, 215, 233, 0.5);
      border-radius: 44px;
      line-height: 88px;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: #ffffff;
    }
    .input-box {
      width: 652px;
      height: 88px;
      background: #f7f8fa;
      border-radius: 44px;
      margin-top: 54px;
      display: flex;
      align-items: center;
      padding: 0 46px;
      box-sizing: border-box;
      .right {
        flex: 1;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
        height: 100%;
        input {
          height: 100%;
          width: 100%;
          background-color: transparent;
        }
      }
      .left {
        margin-right: 14px;
        img {
          width: 36px;
          height: 36px;
        }
      }
    }
    .title {
      font-size: 36px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      padding-bottom: 62px;
    }
  }
  .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
